export const CONNECTION_GAMES = [
  [ //Board #1
    {
      category: "Brazilian Players",
      words: ["Neymar", "Vinicius Junior", "Richarlison", "Casemiro"],
      difficulty: 1,
      //imageSrc: "https://i.ibb.co/Gn36Ypb/Connections-1.png",
    },
    {
      category: "Players Currently On FC Barcelona",
      words: ["Gavi", "Jules Kounde", "Frenkie De Jong", "Joao Cancelo"],
      difficulty: 2,
      //imageSrc: "https://i.ibb.co/3B44zXX/Connections-2.png",
    },
    {
      category: "2022/2023 Premier League Leading Scorers",
      words: ["Erling Haaland", "Harry Kane", "Ivan Toney", "Mohamed Salah"],
      difficulty: 3,
      //imageSrc: "https://i.ibb.co/4MwD7zv/Connections-3.png",
    },

    {
      category: "Most Recent World Cup Golden Ball Winners",
      words: ["Lionel Messi", "Luka Modric", "Diego Forlan", "Zinedine Zidane"],
      difficulty: 4,
      //imageSrc: "https://i.ibb.co/V2vnr5P/boys2men-2-d53afdddf480401fac1204a3954aa5f6.webp",
    },
  ],
  [ //Board #2
    {
      category: "Italian Players",
      words: ["Federico Chiesa", "Alessandro Bastoni", "Nicolo Barella", "Gianluigi Donnarumma"],
      difficulty: 1,
    },
    {
      category: "La Liga Center Midfielders",
      words: ["Federico Valverde", "Eduardo Camavinga", "Pedri", "Koke"],
      difficulty: 2,
    },
    {
      category: "Former Players Turned Managers",
      words: ["Frank Lampard", "Xavi", "Xabi Alonso", "Diego Simeone"],
      difficulty: 3,
    },
    {
      category: "Players With Most CAPS All Time",
      words: ["Cristiano Ronaldo", "Bader Al-Mutawa", "Soh Chin Ann", "Ahmed Hassan"],
      difficulty: 4,
    },
  ],
  [ //Board #3
    {
      category: "Current Players for Bayern Munich",
      words: ["Harry Kane", "Jamal Musiala", "Alphonso Davies", "Kingsley Coman"],
      difficulty: 1,
    },
    {
      category: "Current Premier League Defenders",
      words: ["John Stones", "Virgil Van Dijk", "Williams Saliba", "Luke Shaw"],
      difficulty: 2,
    },
    {
      category: "Most Recent World Cup Golden Boot Winners",
      words: ["Kylian Mbappe", "Harry Kane", "James Rodriguez", "Thomas Muller"],
      difficulty: 3,
    },
    {
      category: "Players Taller Than 6ft 4in (193 cm)",
      words: ["Gerard Pique", "Zlatan Imbrahimovic", "Kalidou Koulibaly","Niklas Sule"],
      difficulty: 4,
      imageSrc: "https://i.ibb.co/pP1xn0Z/Connections.png",
    },
  ],
  [ //Board #4
    {
      category: "Current Liverpool Players",
      words: ["Trent Alexander-Arnold", "Thiago Alcantara", "Darwin Nunez", "Alisson Becker"],
      difficulty: 1,
      //imageSrc: "https://d1isxmzxwhhjbk.cloudfront.net/3/Church-Roles.png",
    },
    {
      category: "Notable Left-Footed Players",
      words: ["Lionel Messi", "Roberto Carlos", "Diego Maradona", "Providential"],
      difficulty: 2,
      //imageSrc: "https://d1isxmzxwhhjbk.cloudfront.net/3/Synonyms-for-Lucky.png",
    },
    {
      category: "2022/2023 Bundesliga Leading Scorers",
      words: ["Niclas Fullkrug", "Christopher Nkunku", "Randal Kolo Muani", "Arjen Robben"],
      difficulty: 3,
      //imageSrc: "https://d1isxmzxwhhjbk.cloudfront.net/3/R-B-Icons.png",
    },

    {
      category: "Most Recent Golden Boy Winners",
      words: ["Jude Bellingham", "Gavi", "Pedri", "Erling Haaland"],
      difficulty: 4,
      //imageSrc: "https://d1isxmzxwhhjbk.cloudfront.net/3/Tupac-Movie-Roles.png",
    },
  ],
  [ //Board #5
    {
      category: "Portugese Players",
      words: ["Cristiano Ronaldo", "Bruno Fernandes", "Joao Felix", "Rafael Leao"],
      difficulty: 1,
    },
    {
      category: "2022/2023 La Liga Leading Scorers",
      words: ["Robert Lewandowski", "Joselu", "Karim Benzema", "Antoine Griezmann"],
      difficulty: 2,
    },
    {
      category: "Broadcasters",
      words: ["Martin Tyler", "Andy Gray", "Ian Darke", "Alan Smith"],
      difficulty: 3,
    },

    {
       category: "Most Recent FIFA Video Game Cover Athletes",
      words: ["Erling Haaland", "Kylian Mbappe", "Virgil Van Dijk", "Eden Hazard"],
      difficulty: 4,
    },
  ],
  [ //Board #6
    {
      category: "Premier League Goalkeepers",
      words: ["Andre Onana", "Jordan Pickford", "Ederson", "Alisson Becker"],
      difficulty: 1,
      //imageSrc: "https://d1isxmzxwhhjbk.cloudfront.net/4/Boondocks-Characters.png",
    },
    {
      category: "Most Recent Ballon d'Or Winners",
      words: ["Lionel Messi", "Karim Benzema", "Luka Modric", "Cristiano Ronaldo"],
      difficulty: 2,
      //imageSrc: "https://d1isxmzxwhhjbk.cloudfront.net/4/Uncle___.png",
    },
    {
      category: "Most Recent African Footballers of the Year",
      words: ["Victor Osimhen", "Sadio Mane", "Mohamed Salah", "Riyad Mahrez"],
      difficulty: 3,
      //imageSrc: "https://d1isxmzxwhhjbk.cloudfront.net/4/Bobby___.png",
    },
    {
      category: "Players 5ft 6in (168cm) or Shorter",
      words: ["Bobby", "Fred", "Elaine", "Kathleen"],
      difficulty: 4,
      //imageSrc: "https://d1isxmzxwhhjbk.cloudfront.net/4/Black-Panthers.png",
    },
  ],
];
