import React from "react";

import InfoModal from "../modals/InfoModal";

function Header() {
  return (
    <header>
      <h1>FootyConnections</h1>
    </header>
  );
}

export default Header;
